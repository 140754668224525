import React, { useEffect, useMemo, useRef, useState } from 'react'
import useCustomTranslation from 'src/utils/translation'
import parse from 'html-react-parser'

import SizeSelect from '../select/SizeSelect'
import { resizeImage } from 'src/utils/image'
import { formattedPrice } from 'src/utils/price'
import {
    getGarmentOptionSizes,
    addGarmentSizeToCart,
    getGarmentSizeLabel,
    isGarmentSizePreorder,
    getIsSizeGroup,
} from 'src/utils/garment'
import { trackEvent } from 'src/utils/tracking'

import { useDispatch } from 'react-redux'
import ImageSmooth from '../image/ImageSmooth'
import useShowModal from 'src/utils/showRecommendationsModal'
import { SetDetailIdAction } from 'src/store/actions/garment'
import { Button, Card, Row, Typography } from 'antd'
import { toggleProductModal } from 'src/store/slices/layoutSlice'
import { useAppSelector } from 'src/store'
import ColorPicker from '../ColorPicker'
import FavoriteButton from '../button/FavoriteButton'
import { useGetGarmentQuery } from 'src/store/api/api-garment'
import { HandleLookRequest } from 'src/store/actions/look'
import { openModelProductDetail, sendWKGotoCart } from 'src/utils/wkwebview'
import StartingAt from '../StartingAt'
import MultiSizeSelect from '../select/MultiSizeSelect'
import useGarmentMethodsHook from 'src/utils/garmentMethodsHook'

const { Title, Paragraph } = Typography

interface CardCartProps {
    garment: Models.Garment
    value: string | Models.MultiSize[]
    onChange(newValue: string | Models.MultiSize[], name: string): void
    ratio: number
    cardFocusFunc?: any
    showRecommendation?: boolean
}

const CardCart: React.FunctionComponent<CardCartProps> = (props) => {
    const { garment, value, ratio, cardFocusFunc, showRecommendation } = props

    const { t } = useCustomTranslation()
    const { showModal, isLoading } = useShowModal()
    const dispatch = useDispatch()
    const { convertedPrice } = useGarmentMethodsHook(garment)

    const priceFloat = useAppSelector((state) => state.profile?.company?.price_float)
    const descriptionCatalog = useAppSelector(
        (state) => state.profile?.company?.description_catalog
    )
    const cartUrl = useAppSelector((state) => state.profile?.company?.external_cart_url)
    const internal = useAppSelector((state) => state.profile?.company?.internal)
    const useClipping = useAppSelector((state) => state.profile?.company?.use_clipping)
    const company = useAppSelector((state) => state.profile?.company)
    const look = useAppSelector((state) => state.look?.request)

    const [addingToCart, setAddingToCart] = useState(false)
    const [goToCart, setGoToCart] = useState(false)
    const [colorSelected, setColorSelected] = useState<{ name: string; colorUrl: string }>()

    const sizeSelectRef = useRef(null)

    const { data: variantData } = useGetGarmentQuery(
        {
            type: garment.garment_type,
            page: 1,
            filter: { garment_variant_ids: [garment.garment_id] },
        },
        { skip: !garment || !garment.garment_variant_color }
    )

    const allVariantColors = useMemo(() => {
        if (!variantData) {
            return []
        }
        if (!variantData.items.length) {
            return [{ name: garment.product_color, colorUrl: garment.garment_variant_color }]
        }
        return variantData.items.map((variant) => {
            return { name: variant.product_color, colorUrl: variant.garment_variant_color }
        })
    }, [garment, variantData])

    // ---- Boolean used to narrow product_sizes type ----
    const isSizeGroup = useMemo(() => getIsSizeGroup(garment.product_sizes), [garment])

    const optionSize = getGarmentOptionSizes(garment)

    const imageUrl =
        useClipping && garment.image_clipping_url ? garment.image_clipping_url : garment.image_url

    useEffect(() => {
        setGoToCart(false)
    }, [value])

    useEffect(() => {
        if (cardFocusFunc) {
            cardFocusFunc.current = () => {
                if (!value) {
                    return sizeSelectRef.current?.focus()
                }
            }
        }
        // eslint-disable-next-line
    }, [cardFocusFunc])

    useEffect(() => {
        if (garment && garment.garment_variant_color) {
            setColorSelected({
                name: garment.product_color,
                colorUrl: garment.garment_variant_color,
            })
        }
    }, [garment])

    const handleChange = (newValue: string | Models.MultiSize[] | null, name) => {
        if (newValue) {
            trackEvent(
                'Size Selected',
                [
                    garment,
                    {
                        item_size_selected: newValue,
                        item_size_selected_label:
                            typeof newValue === 'string'
                                ? getGarmentSizeLabel(newValue, optionSize)
                                : undefined,
                    },
                ],
                'Outfit Detail'
            )
        }
        props.onChange(newValue, name)
    }

    const handleAddToCart = () => {
        let sizeGroupFieldCount = 0
        garment.product_sizes.forEach((garmentSize) => {
            if ((garmentSize as Models.GarmentSize).fields) {
                sizeGroupFieldCount += garmentSize.fields.length
            }
        })

        if (!value || (isSizeGroup && value.length !== sizeGroupFieldCount)) {
            return sizeSelectRef.current?.focus()
        }

        trackEvent(
            'Item Added to cart',
            [
                garment,
                {
                    item_size_selected: value,
                    item_size_selected_label:
                        typeof value === 'string'
                            ? getGarmentSizeLabel(value, optionSize)
                            : undefined,
                },
                look.model,
            ],
            'Outfit Detail'
        )
        setAddingToCart(true)
        addGarmentSizeToCart(garment, value, 'Outfit Detail', (success) => {
            if (!success) {
                trackEvent(
                    'Error Adding item to cart',
                    [
                        garment,
                        {
                            item_size_selected: value,
                            item_size_selected_label:
                                typeof value === 'string'
                                    ? getGarmentSizeLabel(value, optionSize)
                                    : undefined,
                        },
                    ],
                    'Outfit Detail'
                )
                alert(t(`error.cart`))
            } else {
                setGoToCart(true)
                if (showRecommendation) {
                    showModal(garment.garment_id, look, 'Outfit Detail')
                }
            }
            setAddingToCart(false)
        })
    }

    const handleGoToCart = (e) => {
        e.stopPropagation()
        if (sendWKGotoCart()) {
            e.preventDefault()
        }
        trackEvent('Go to cart Clicked', [garment], 'Outfit Detail')
    }

    const handleMoreLook = (e) => {
        e.stopPropagation()
        showModal(garment.garment_id, garment, 'Outfit Detail')
    }

    const handleDetailClick = () => {
        trackEvent('Item Detailed', garment, 'Outfit Detail')
        if (!openModelProductDetail(garment)) {
            dispatch(SetDetailIdAction(garment.garment_id))
            dispatch(toggleProductModal())
        }
    }

    const handleColorChange = (newColor: { name: string; colorUrl: string }) => {
        setColorSelected(newColor)
        const variant = variantData?.items.find(
            (localVariant) => localVariant.garment_variant_color === newColor.colorUrl
        )

        if (!variant) {
            return
        }

        dispatch(
            HandleLookRequest({
                lookRequest: {
                    [garment.garment_type.toLowerCase()]: variant,
                },
                keepAdditional: true,
                focus: garment.garment_type,
            })
        )
    }

    const isAllAddToCartLoading =
        addingToCart || (isLoading && window.innerWidth / window.innerHeight >= 1)

    const garmentOffer: Models.OldGarmentSize = !isSizeGroup
        ? (garment.product_sizes as Models.OldGarmentSize[]).find(
              (size) => !('sizes' in size) && size.value === (value as string)
          )
        : undefined
    const garmentPrice =
        garmentOffer?.price || convertedPrice?.product_price || garment.product_price
    const garmentOriginalPrice =
        garmentOffer?.price_original ||
        convertedPrice?.product_price_original ||
        garment.product_price_original
    const isPromotion = !!garmentOriginalPrice && garmentOriginalPrice > garmentPrice
    const garmentCurrency = convertedPrice?.product_currency || garment.product_currency

    return (
        <Card
            className={`card-cart card-cart--container card-cart--container-swipe override_card_container card-cart-${garment.garment_type}`}
            cover={
                <>
                    <div className='card-cart--image'>
                        {garment.cart_add_image_html && parse(garment.cart_add_image_html)}
                        <ImageSmooth
                            src={resizeImage(imageUrl, { width: 800 })}
                            ratio={ratio}
                            lazyload={false}
                        />
                    </div>
                    <Button
                        type='text'
                        onClick={handleDetailClick}
                        className='button--underlined'
                        size='small'
                    >
                        {t(`product.see_product`)}
                    </Button>
                    <FavoriteButton
                        data={garment}
                        className='card-cart--favorite-button'
                        eventCategory='Outfit Detail'
                    />
                </>
            }
            classNames={{
                cover: 'card-cart--col',
                body: 'card-cart--content',
            }}
        >
            <>
                {garment.product_brand && (
                    <Paragraph
                        ellipsis={{
                            rows: 2,
                        }}
                        className='text text--small'
                    >
                        {parse(garment.product_brand)}
                    </Paragraph>
                )}
                <Title
                    ellipsis={{
                        rows: 3,
                    }}
                    className='title title--h3 card-cart--title'
                >
                    {parse(garment.product_name)}
                </Title>
                {garment.product_subname && (
                    <Paragraph
                        ellipsis={{
                            rows: 2,
                        }}
                        className='text text--small card-cart--subname'
                    >
                        {parse(garment.product_subname)}
                    </Paragraph>
                )}
                {descriptionCatalog !== false && (
                    <Paragraph
                        ellipsis={{
                            rows: 2,
                        }}
                        className='text text--small'
                    >
                        {parse(garment.product_description)}
                    </Paragraph>
                )}
                <Row className='card--price--container'>
                    {!value && <StartingAt garment={garment} />}

                    {isPromotion && (
                        <Title
                            ellipsis={{
                                rows: 1,
                            }}
                            className='title card--price--promotion'
                        >
                            <span className='card--price--original'>
                                {convertedPrice && convertedPrice.product_price_original_string
                                    ? convertedPrice.product_price_original_string
                                    : formattedPrice(
                                          garmentOriginalPrice,
                                          garmentCurrency,
                                          priceFloat
                                      )}
                            </span>
                            {internal === 'gemofr' ? (
                                <span className='card--price--percent'>PROMO</span>
                            ) : (
                                <span className='card--price--percent'>
                                    -
                                    {Math.round(
                                        ((garmentOriginalPrice - garmentPrice) * 100) /
                                            garmentOriginalPrice
                                    )}
                                    %
                                </span>
                            )}
                        </Title>
                    )}
                    <Title
                        ellipsis={{
                            rows: 1,
                        }}
                        className={`title title--h3 card--price--final${
                            isPromotion ? ' card--price--final--promotion' : ''
                        }`}
                    >
                        {convertedPrice && convertedPrice.product_price_string
                            ? convertedPrice.product_price_string
                            : formattedPrice(garmentPrice, garmentCurrency, priceFloat)}
                    </Title>
                </Row>

                <div className='card-cart--flex'>
                    {garment.garment_variant_color && (
                        <ColorPicker
                            garment={garment}
                            value={colorSelected}
                            onValueChange={handleColorChange}
                            colors={allVariantColors}
                            eventCategory='Outfit Detail'
                        />
                    )}
                    {isSizeGroup ? (
                        <MultiSizeSelect
                            ref={sizeSelectRef}
                            garmentSizes={garment.product_sizes as Models.GarmentSize[]}
                            onChange={handleChange}
                            garmentDefaultFilter={garment.product_sizes_default_filter}
                            garmentFilters={garment.product_sizes_filters}
                        />
                    ) : (
                        <SizeSelect
                            ref={sizeSelectRef}
                            name='size'
                            onChange={handleChange}
                            value={value as string}
                            options={optionSize}
                            translation={false}
                            placeholder={t('product.choose_size')}
                            customStyle={{ flex: 1 }}
                        />
                    )}

                    {goToCart && cartUrl && !isAllAddToCartLoading ? (
                        <Button
                            className='button--primary button--go-to-cart'
                            onClick={handleGoToCart}
                            href={cartUrl}
                            target={company.link_target_parent ? '_parent' : '_blank'}
                            type='primary'
                            size='large'
                            key='goToCart'
                        >
                            {t('product.go_to_cart')}
                        </Button>
                    ) : (
                        <Button
                            className='button--primary button--add-to-cart'
                            onClick={handleAddToCart}
                            type='primary'
                            size='large'
                            loading={isAllAddToCartLoading}
                        >
                            {t(
                                typeof value === 'string' &&
                                    isGarmentSizePreorder(value, optionSize)
                                    ? 'product.add_to_cart_preorder'
                                    : 'product.add_to_cart'
                            )}
                        </Button>
                    )}
                    {goToCart && company.enable_recommendations && (
                        <Button
                            className='button--mobile button--more-look'
                            onClick={handleMoreLook}
                            loading={isLoading}
                            size='large'
                        >
                            {t('cart.more_look')}
                        </Button>
                    )}
                </div>
            </>
        </Card>
    )
}

export default CardCart
