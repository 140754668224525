// @ts-nocheck
import ReactGA from 'react-ga'
import { init, getDeviceId, getSessionId, track } from '@amplitude/analytics-browser'
import { DEBUG_MODE } from 'src/settings/global'
import { getQueryValue, getQueryLocale } from 'src/utils/query'
import { inIframe, sendIframeMessage } from 'src/utils/iframe'
import store from 'src/store'
import { PostInitEvent } from 'src/services/event'
import { inWKWebView, sendWKScriptMessage } from './wkwebview'
import { isGarmentSizePreorder } from './garment'
import { getDevice } from './device'

const shouldEnableAnalytics =
    window.location.hostname !== 'localhost' &&
    ['0', 'false'].indexOf(getQueryValue('analytics')) === -1
const trackingDebug =
    DEBUG_MODE ||
    (!window.location.hostname.match(/^([^.]+\.)?experience\.veesual\.ai$/) &&
        !window.location.hostname.match(
            /^https?:\/\/generate-experience-[0-9a-z]+-veesual\.vercel\.app(\/|\?|#|$)/
        ))

const hasAnalytics = () => {
    return true
}

const hasGoogleAnalytics = () => {
    const googleAnalytics = store.getState().profile?.company?.google_analytics
    if (googleAnalytics !== null) {
        if (
            (Array.isArray(googleAnalytics) && googleAnalytics.length) ||
            (typeof googleAnalytics === 'string' && googleAnalytics !== '')
        ) {
            return true
        }
    }
    return false
}

let areAnalyticsLoaded = false
let areGoogleAnalyticsLoaded = false
let isTrackingInitiated = false
const initTracking = (force = false) => {
    // ---- Skip if already initiated ----
    if (isTrackingInitiated && !force) {
        return
    }

    // ---- Initiated ----
    isTrackingInitiated = true

    // ---- Segment ----
    if (hasAnalytics()) {
        if (shouldEnableAnalytics || force) {
            if (trackingDebug) {
                init('14b31c452b81ab5f02276fcba7a343bc', null, {
                    serverUrl: 'https://segment.veesual.ai/event',
                    defaultTracking: false,
                })
            } else {
                init('6745d3ed5a0f89fce03654efe4a92e81', null, {
                    serverUrl: 'https://segment.veesual.ai/event',
                    defaultTracking: false,
                })
            }
            areAnalyticsLoaded = true
        }
    }

    // ---- GA ----
    if (hasGoogleAnalytics()) {
        if (shouldEnableAnalytics || force) {
            const googleAnalytics = store.getState().profile?.company?.google_analytics
            if (googleAnalytics !== null) {
                ReactGA.initialize(googleAnalytics, { debug: trackingDebug })
            }
            areGoogleAnalyticsLoaded = true
        }
    }
}

const getBasicProperties = () => {
    let origin = getQueryValue('origin')
    if (origin !== null) {
        const originSplit = origin.split('?')
        if (originSplit.length == 2) {
            let originQuery = originSplit[1]
            originQuery = originQuery.replace(
                /(^|&)(utm_[^&#=]*|fbclid|gclid|gclsrc|_ga|mc_cid|mc_eid|_branch_match_id)(=[^&#]*)?/gi,
                ''
            )
            originQuery = originQuery.replace(/^&/, '')
            origin = originSplit[0] + (originQuery != '' ? '?' + originQuery : '')
        }
    }

    const extras = {}
    for (let i = 1; i <= 5; i++) {
        const extraField = `extra_param${i > 1 ? i : ''}`
        const extraValue = getQueryValue(extraField)
        if (extraValue !== null && extraValue !== '') {
            extras[extraField] = extraValue
        }
    }

    const utms = {}
    for (const allowedUtm of [
        'utm_source',
        'utm_medium',
        'utm_campaign',
        'utm_term',
        'utm_content',
    ]) {
        const utm = getQueryValue(allowedUtm)
        if (utm !== null) {
            utms[allowedUtm] = utm
        }
    }

    const state = store.getState()
    return Object.assign(
        {
            module: 'styling_experience',
            client_id: state.profile.company.id,
            client_internal: state.profile.company.internal,
            client_name: state.profile.company.name,
            group_id: state.profile.company.group_id || null,
            group_name: state.profile.company.group_name || null,
            experience_id: state.profile.company.experience_id || null,
            experience_name: state.profile.company.experience_name || null,
            experience: getQueryValue('experience'),
            locale: getQueryLocale(),
            domain: getQueryValue('domain'),
            origin: origin,
            cta_location: getQueryValue('cta_location'),
            cta_version: getQueryValue('cta_version'),
            device: getDevice(),
            version: 2.0,
        },
        extras,
        utms
    )
}

const getPageNameAndProperties = (path = null) => {
    const finalPath = path !== null ? path : window.location.pathname
    switch (finalPath) {
        case '/':
            return { name: 'Home', properties: {} }
        case '/model':
            return { name: 'Model Choice', properties: {} }
        case '/product':
            return { name: 'Item Detail', properties: {} }
        case '/favorites':
            const state = store.getState()
            return {
                name: 'Favorite',
                properties: { favorite_type: state.favorites.isOutfit ? 'outfit' : 'item' },
            }
        case '/cart':
            return { name: 'Outfit Detail', properties: {} }
        case '/swipe':
            return { name: 'Swipe', properties: {} }
        case '/catalog':
            return { name: 'Catalog', properties: {} }
    }
    return { name: 'Unknown', properties: {} }
}

export const getPageName = (path = null) => {
    return getPageNameAndProperties(path).name
}

const doTrackPage = async () => {
    // ---- Ajout des basics ----
    const pageAndProperties = getPageNameAndProperties()
    const finalProperties = Object.assign(getBasicProperties(), pageAndProperties.properties)

    // ---- Debug ----
    if (trackingDebug) {
        console.log('trackPage', pageAndProperties.name, finalProperties)
    }

    // ---- Lancement de l'event ----
    if (areAnalyticsLoaded) {
        track(`Viewed ${pageAndProperties.name} Page`, finalProperties)
    }
    if (areGoogleAnalyticsLoaded) {
        ReactGA.pageview(window.location.pathname, pageAndProperties.name)
    }
}

const getPropertyHavingKey = (properties?: any, key) => {
    // ---- Traduction des properties ----
    if (properties !== undefined && properties !== null) {
        if (Array.isArray(properties)) {
            for (const one of properties) {
                const property = getPropertyHavingKey(one, key)
                if (property !== null) {
                    return property
                }
            }
        } else if (typeof properties[key] !== 'undefined') {
            return properties
        }
    }
    return null
}

const addPrefixToProperties = (prefix, properties: any) => {
    const prefixed = {}
    for (const key in properties) {
        prefixed[prefix + key] = properties[key]
    }
    return prefixed
}

const convertProperties = (properties?: any) => {
    // ---- Traduction des properties ----
    let convertedProperties = {}
    if (properties !== undefined && properties !== null) {
        if (Array.isArray(properties)) {
            for (const one of properties) {
                convertedProperties = Object.assign(convertedProperties, convertProperties(one))
            }
        } else if (typeof properties.garment_id !== 'undefined') {
            convertedProperties = {
                item_internal_id: properties.garment_id,
                item_external_id: properties.product_external_id || null,
                item_name: properties.product_name,
                item_type: properties.garment_type,
                item_price: properties.product_price,
            }
        } else if (typeof properties.model_id !== 'undefined') {
            convertedProperties = {
                model_id: properties.model_id,
                model_name: properties.model_name,
                model_height: properties.model_height,
                model_garment_size: properties.model_garment_size,
            }
        } else if (typeof properties.look_id !== 'undefined') {
            const state = store.getState()
            const garmentTypes = state.profile.company.garment_types || [
                'TOP',
                'BOTTOM',
                'DRESS',
                'OUTERWEAR',
            ]
            let nbItems = 0
            let totalPrice = 0
            const types = []
            for (const type of garmentTypes) {
                const typeLower = type.toLowerCase()
                if (properties[typeLower]) {
                    nbItems += 1
                    totalPrice += properties[typeLower].product_price
                    types.push(type)
                }
            }
            convertedProperties = Object.assign(
                {
                    outfit_id: properties.look_id,
                    outfit_nb_items: nbItems,
                    outfit_total_price: totalPrice,
                    outfit_types: types.join(','),
                },
                convertProperties(properties.model)
            )
            for (const type of garmentTypes) {
                const typeLower = type.toLowerCase()
                if (properties[typeLower]) {
                    convertedProperties = Object.assign(
                        convertedProperties,
                        addPrefixToProperties(
                            typeLower + '_',
                            convertProperties(properties[typeLower])
                        )
                    )
                }
            }
        } else if (typeof properties === 'object') {
            convertedProperties = properties
        }
    }
    return convertedProperties
}

const convertFinalProperties = (name: string, properties: Record<string, any>) => {
    // ---- Surcharge globale si besoin ----
    if (name === 'Item Added to cart') {
        return Object.assign(properties, {
            revenue: properties.item_price,
            price: properties.item_price,
            currency: 'EUR',
            // ---- Old segment fields ----
            $revenue: properties.item_price,
            $price: properties.item_price,
            $quantity: 1,
        })
    }
    if (name === 'Items Loaded') {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { items, ...filteredProperties } = properties

        return Object.assign(filteredProperties, { nb_items: properties.items.length })
    }

    return properties
}

const postInitEventWhenDeviceAndSession = (
    type: string,
    finalProperties: Record<string, any>,
    n = 0
) => {
    const deviceId = getDeviceId()
    const sessionId = getSessionId()
    if (deviceId !== undefined && sessionId !== undefined) {
        PostInitEvent(Object.assign({ type, deviceId, sessionId }, finalProperties))
    } else if (n < 10) {
        setTimeout(() => {
            postInitEventWhenDeviceAndSession(type, finalProperties, n + 1)
        }, 500)
    }
}

const getEileenFisherGaProductData = (garmentProperty, finalProperties = null) => {
    const colorMatch = garmentProperty.product_external_url.match(
        /(\?|&)dwvar_[^&]+_color=([0-9]+)/
    )

    return {
        name: garmentProperty.product_name,
        id: garmentProperty.product_external_id,
        product_url: garmentProperty.product_external_url,
        product_image_url: garmentProperty.image_url,
        price: finalProperties ? finalProperties.item_price : garmentProperty.product_price,
        dimension98: '',
        dimension10: 'closet',
        dimension9: '',
        dimension11: '',
        dimension18: '',
        dimension19: 'NA',
        dimension20: 'NA',
        dimension21: '',
        dimension22: 'closet',
        dimension23: '',
        dimension24: '',
        dimension25: garmentProperty.product_color,
        dimension26: '',
        dimension27: colorMatch ? colorMatch[2] : '',
        dimension28: '',
        dimension29: '',
        dimension30: isGarmentSizePreorder(null, garmentProperty.product_sizes)
            ? 'pre-order'
            : 'IN_STOCK',
        dimension31: finalProperties ? finalProperties.item_size_selected_label : '',
        dimension32: garmentProperty.product_price_original || garmentProperty.product_price,
        dimension33: '',
        dimension35: '',
        dimension76: '',
        dimension77: '',
        category: '',
        quantity: '1',
        metric1: finalProperties ? finalProperties.item_price : garmentProperty.product_price,
        metric2: garmentProperty.product_price_original || garmentProperty.product_price,
        variant: finalProperties
            ? finalProperties.item_size_selected
            : garmentProperty.product_sizes[0].offer_id,
        brand: 'EF',
        list: 'closet',
    }
}

const doTrackEvent = async (name: string, properties?: any, category: string = null) => {
    // ---- Traduction des properties ----
    const convertedProperties = convertProperties(properties)
    const pageAndProperties = getPageNameAndProperties()
    const convertedPageProperties = Object.assign(
        { page_view: pageAndProperties.name },
        pageAndProperties.properties
    )
    const convertedCategory = { category: category }

    // ---- Ajout des basics ----
    const finalProperties = convertFinalProperties(
        name,
        Object.assign(
            convertedCategory,
            getBasicProperties(),
            convertedPageProperties,
            convertedProperties
        )
    )

    // ---- Debug ----
    if (trackingDebug) {
        console.log('trackEvent', name, finalProperties, areAnalyticsLoaded)
    }

    // ---- Lancement de l'event ----
    if (areAnalyticsLoaded || areGoogleAnalyticsLoaded) {
        // ---- Amplitude ----
        if (areAnalyticsLoaded) {
            track(name, finalProperties)
        }
        // ---- React ga ----
        if (areGoogleAnalyticsLoaded) {
            ReactGA.event({
                category: finalProperties.category,
                action: name,
            })
        }
        // ---- External events ----
        if (store.getState().profile.company.enable_event) {
            switch (name) {
                case 'Init':
                    postInitEventWhenDeviceAndSession('INIT', finalProperties)
                    break
                case 'Item Added to cart':
                    postInitEventWhenDeviceAndSession('ADD_TO_CART', finalProperties)
                    break
            }
        }
        // ---- Laredoute ----
        if (inIframe() && finalProperties.client_internal.match(/^laredoute/)) {
            // ---- Envoi d'une liste restreinte d'events ----
            if (
                [
                    'Init',
                    'Outfit Created',
                    'Item Selected',
                    'Model Selected',
                    'Item Added to cart',
                    'Item Saved to favorite',
                ].indexOf(name) !== -1
            ) {
                sendIframeMessage('veesual_execute', [
                    {
                        type: 'javascript',
                        payload: `window.dispatchEvent(new CustomEvent('veesual_event:${name.replace(
                            /'/,
                            "\\'"
                        )}', {detail: JSON.parse('${JSON.stringify(finalProperties).replace(
                            /'/,
                            "\\'"
                        )}')}));`,
                    },
                ])
            }
        }
        // ---- Gemo ----
        if (inIframe() && finalProperties.client_internal.match(/^gemo/)) {
            // ---- En fonction de l'event ----
            let eventName = null,
                eventData = null
            switch (name) {
                case 'Init':
                    eventName = 'page.display'
                    eventData = {
                        page: 'veesual_experience',
                        x6: 'veesual_experience',
                    }
                    break
                case 'Outfit Created':
                    eventName = 'veesual.outfit_created'
                    eventData = {
                        outfit_nb_items: finalProperties.outfit_nb_items,
                        outfit_total_price: finalProperties.outfit_total_price,
                        outfit_types: finalProperties.outfit_types,
                    }
                    break
                case 'Item Selected':
                    eventName = 'veesual.item_selected'
                    eventData = {
                        item_type: finalProperties.item_type,
                        item_price: finalProperties.item_price,
                        item_name: finalProperties.item_name,
                        item_external_id: finalProperties.item_external_id,
                    }
                    break
                case 'Model Selected':
                    eventName = 'veesual.model_selected'
                    eventData = {
                        model_name: finalProperties.model_name,
                        model_height: finalProperties.model_height,
                        model_garment_size: finalProperties.model_garment_size,
                    }
                    break
                case 'Item Added to cart':
                    eventName = 'veesual.add_to_cart'
                    eventData = {
                        item_type: finalProperties.item_type,
                        item_price: finalProperties.item_price,
                        item_name: finalProperties.item_name,
                        item_size_selected_label: finalProperties.item_size_selected_label,
                        item_external_id: finalProperties.item_external_id,
                    }
                    break
            }
            if (eventName !== null && eventData !== null) {
                sendIframeMessage('veesual_execute', [
                    {
                        type: 'javascript',
                        payload: `pa.sendEvent('${eventName.replace(
                            /'/,
                            "\\'"
                        )}', JSON.parse('${JSON.stringify(eventData).replace(/'/, "\\'")}'));`,
                    },
                ])
            }
        }
        // ---- GPE ----
        if (inIframe() && finalProperties.client_internal.match(/^(sergentmajor|dpam)/)) {
            // ---- Envoi d'une liste restreinte d'events ----
            if (
                ['Init', 'Outfit Created', 'Item Selected', 'Item Added to cart'].indexOf(name) !==
                -1
            ) {
                sendIframeMessage('veesual_execute', [
                    {
                        type: 'javascript',
                        payload: `window.dataLayer = window.dataLayer || []; window.dataLayer.push({
                            'event': 'veesual_switchmodel_${name
                                .toLowerCase()
                                .replace(/\s+/g, '_')
                                .replace(/'/g, "\\'")}',
                            'veesual': JSON.parse('${JSON.stringify(finalProperties).replace(
                                /'/g,
                                "\\'"
                            )}')
                        });`,
                    },
                ])
            }
        }
        // ---- Eileen Fisher ----
        if (inIframe() && finalProperties.client_internal.match(/^eileenfisher/)) {
            // ---- Raccourcis ----
            const lookProperty = getPropertyHavingKey(properties, 'look_id')
            const garmentProperty = getPropertyHavingKey(properties, 'garment_id')
            const itemsProperty = getPropertyHavingKey(properties, 'items')

            // ---- En fonction de l'event ----
            let eventData = null
            switch (name) {
                case 'Init':
                    // ---- Verification de l'origin ----
                    const origin = getQueryValue('origin')
                    if (
                        origin &&
                        origin.match(/^https?:\/\/([^\/]+\.)?eileenfisher\.com(\/|\?|#|$)/)
                    ) {
                        // ---- Injection de contensquare ----
                        const scriptId = 'efContentSquare'
                        if (!document.getElementById(scriptId)) {
                            const scriptElem = document.createElement('script')
                            scriptElem.id = scriptId
                            scriptElem.innerHTML = `
                                (function () {
                                    window._uxa = window._uxa || [];
                                    if (typeof CS_CONF === 'undefined') {
                                        window._uxa.push(['setPath', window.location.pathname+window.location.hash.replace('#','?__')]);
                                        var mt = document.createElement('script'); mt.type = 'text/javascript'; mt.async = true;
                                        mt.src = '//t.contentsquare.net/uxa/a84309e5ded9f.js';
                                        document.getElementsByTagName('head')[0].appendChild(mt);
                                    }
                                    else {
                                        window._uxa.push(['trackPageview', window.location.pathname+window.location.hash.replace('#','?__')]);
                                    }
                                })();
                            `
                            document.head.append(scriptElem)
                        }
                    }
                    break
                case 'Look Saved to favorite':
                    if (lookProperty !== null) {
                        eventData = {
                            eventProduct: [],
                            eventLocation: 'closet: ' + finalProperties.category.toLowerCase(),
                            editType: 'add_multiple',
                            event: 'wishlistClick',
                            productStatus: [],
                            variant: [],
                            name: [],
                            price: [],
                            metric2: [],
                            dimension21: [],
                            category: [],
                            dimension25: [],
                            dimension27: [],
                            dimension31: [],
                            outfitValue: 0,
                        }
                        const state = store.getState()
                        const garmentTypes = state.profile.company.garment_types
                        for (const type of garmentTypes) {
                            if (properties[type.toLowerCase()]) {
                                const oneProperty = properties[type.toLowerCase()]
                                const oneColorMatch = oneProperty.product_external_url.match(
                                    /(\?|&)dwvar_[^&]+_color=([0-9]+)/
                                )
                                eventData['eventProduct'].push(oneProperty.product_external_id)
                                eventData['productStatus'].push(
                                    isGarmentSizePreorder(null, oneProperty.product_sizes)
                                        ? 'pre-order'
                                        : 'IN_STOCK'
                                )
                                eventData['variant'].push(oneProperty.product_sizes[0].offer_id)
                                eventData['name'].push(oneProperty.product_name)
                                eventData['price'].push(oneProperty.product_price)
                                eventData['metric2'].push(
                                    oneProperty.product_price_original || oneProperty.product_price
                                )
                                eventData['dimension25'].push(oneProperty.product_color)
                                eventData['dimension27'].push(oneColorMatch ? oneColorMatch[2] : '')
                            }
                        }
                        eventData['outfitValue'] = eventData['price'].reduce((a, b) => a + b, 0)
                        for (const key in eventData) {
                            if (Array.isArray(eventData[key])) {
                                eventData[key] = eventData[key].join(',')
                            }
                        }
                    }
                    break
                case 'Item Saved to favorite':
                    if (garmentProperty !== null) {
                        const colorMatch = garmentProperty.product_external_url.match(
                            /(\?|&)dwvar_[^&]+_color=([0-9]+)/
                        )
                        eventData = {
                            eventProduct: garmentProperty.product_external_id,
                            eventLocation: 'closet: ' + finalProperties.category.toLowerCase(),
                            editType: 'add',
                            event: 'wishlistClick',
                            productStatus: isGarmentSizePreorder(
                                null,
                                garmentProperty.product_sizes
                            )
                                ? 'pre-order'
                                : 'IN_STOCK',
                            variant: garmentProperty.product_sizes[0].offer_id,
                            name: garmentProperty.product_name,
                            price: garmentProperty.product_price,
                            metric2:
                                garmentProperty.product_price_original ||
                                garmentProperty.product_price,
                            dimension21: '',
                            category: '',
                            dimension25: garmentProperty.product_color,
                            dimension27: colorMatch ? colorMatch[2] : '',
                            dimension31: '',
                        }
                    }
                    break
                case 'Item Added to cart':
                    if (garmentProperty !== null) {
                        const itemProductData = getEileenFisherGaProductData(
                            garmentProperty,
                            finalProperties
                        )
                        itemProductData.brand = finalProperties.model_name
                        eventData = {
                            event: 'addToCart',
                            ecommerce: {
                                add: {
                                    products: [itemProductData],
                                },
                            },
                        }
                    }
                    break
                case 'Item Detailed':
                    if (garmentProperty !== null) {
                        eventData = {
                            event: 'detail',
                            ecommerce: {
                                detail: {
                                    products: [getEileenFisherGaProductData(garmentProperty)],
                                },
                            },
                        }
                    }
                    break
                case 'Go to external Clicked':
                    if (garmentProperty !== null) {
                        const colorMatch = garmentProperty.product_external_url.match(
                            /(\?|&)dwvar_[^&]+_color=([0-9]+)/
                        )
                        eventData = {
                            eventProduct: garmentProperty.product_external_id,
                            eventLocation: 'closet: ' + finalProperties.category.toLowerCase(),
                            event: 'closet_to_product',
                            productStatus: isGarmentSizePreorder(
                                null,
                                garmentProperty.product_sizes
                            )
                                ? 'pre-order'
                                : 'IN_STOCK',
                            variant: garmentProperty.product_sizes[0].offer_id,
                            name: garmentProperty.product_name,
                            price: garmentProperty.product_price,
                            metric2:
                                garmentProperty.product_price_original ||
                                garmentProperty.product_price,
                            dimension21: '',
                            category: '',
                            dimension25: garmentProperty.product_color,
                            dimension27: colorMatch ? colorMatch[2] : '',
                            dimension31: '',
                        }
                    }
                    break
                case 'Item Selected':
                case 'Item Clicked':
                    if (garmentProperty !== null) {
                        eventData = {
                            event: 'productClick',
                            ecommerce: {
                                click: {
                                    currencyCode: 'USD',
                                    actionField: {
                                        list: 'closet: ' + finalProperties.category.toLowerCase(),
                                        action: 'click',
                                    },
                                    products: [getEileenFisherGaProductData(garmentProperty)],
                                },
                            },
                        }
                    }
                    break
                case 'Items Loaded':
                    if (itemsProperty !== null) {
                        const items = []
                        for (const i in itemsProperty.items) {
                            const itemProductData = getEileenFisherGaProductData(
                                itemsProperty.items[i]
                            )
                            itemProductData.position =
                                Math.max(0, parseInt(itemsProperty.current_page_number) - 1) *
                                    parseInt(itemsProperty.num_items_per_page) +
                                parseInt(i) +
                                1
                            items.push(itemProductData)
                        }
                        eventData = {
                            event: 'productImpressions',
                            ecommerce: {
                                currencyCode: 'USD',
                                impressions: items,
                            },
                        }
                    }
                    break
                case 'Multi-Experience Selected':
                    eventData = {
                        eventSelection: finalProperties.multi_experience_name.toLowerCase(),
                        eventLocation: 'edit_list',
                        event: 'closetInteraction',
                    }
                    break
                case 'Type Clicked':
                    eventData = {
                        eventSelection: 'see_' + finalProperties.catalog_type.toLowerCase(),
                        eventLocation: 'left_nav_category',
                        event: 'closetInteraction',
                    }
                    break
                case 'Choose model Clicked':
                    eventData = {
                        eventSelection: 'change_your_model',
                        eventLocation: 'left_nav',
                        event: 'closetInteraction',
                    }
                    break
                case 'Tuck in Changed':
                    eventData = {
                        eventSelection: 'tucked_in',
                        eventLocation: 'checkbox',
                        event: 'closetInteraction',
                    }
                    break
                case 'Back Changed':
                    eventData = {
                        eventSelection: 'back',
                        eventLocation: 'checkbox',
                        event: 'closetInteraction',
                    }
                    break
                case 'Zoom Opened':
                    eventData = {
                        eventSelection: 'zoom',
                        event: 'closetInteraction',
                    }
                    break
            }
            if (eventData !== null) {
                sendIframeMessage('veesual_execute', [
                    {
                        type: 'javascript',
                        payload: `window.dataLayer = window.dataLayer || []; window.dataLayer.push(
                            JSON.parse('${JSON.stringify(eventData).replace(/'/, "\\'")}')
                        );`,
                    },
                ])
            }
        }
        // ---- Bluebungalow ----
        if (inIframe() && finalProperties.client_internal.match(/^(bluebungalow)/)) {
            // ---- Envoi d'une liste restreinte d'events ----
            if (
                [
                    'Init',
                    'Outfit Created',
                    'Item Selected',
                    'Item Added to cart',
                    'Choose model Clicked',
                ].indexOf(name) !== -1
            ) {
                sendIframeMessage('veesual_execute', [
                    {
                        type: 'javascript',
                        payload: `window.dataLayer = window.dataLayer || []; window.dataLayer.push({
                                    'event': 'veesual_stylingexperience_${name
                                        .toLowerCase()
                                        .replace(/\s+/g, '_')
                                        .replace(/'/g, "\\'")}',
                                    'veesual': JSON.parse('${JSON.stringify(
                                        finalProperties
                                    ).replace(/'/g, "\\'")}')
                                });`,
                    },
                ])
            }
        }

        // ---- Gestion du bridge ----
        const bridgeConfigs = [
            { regex: /^(sandro|gemo|eileenfisher)/, partner: ['contentsquare'] },
            { regex: /^jules/, partner: ['jules', 'contentsquare'] },
            { regex: /^adoreme/, partner: ['adoreme'] },
        ]
        for (const bridgeConfig of bridgeConfigs) {
            if (inIframe() && finalProperties.client_internal.match(bridgeConfig.regex)) {
                // ---- Envoi d'une liste restreinte d'events ----
                if (
                    [
                        'Init',
                        'Outfit Created',
                        'Item Selected',
                        'Model Selected',
                        'Item Added to cart',
                    ].indexOf(name) !== -1
                ) {
                    sendIframeMessage('veesual_execute', [
                        {
                            type: 'event',
                            partner: bridgeConfig.partner,
                            payload: {
                                name: name,
                                properties: finalProperties,
                                timestamp: Date.now(),
                            },
                        },
                    ])
                }
            }
        }
    }

    // ---- Special pour lulu in app ----
    if (inWKWebView() && ['lululemon.com'].indexOf(getQueryValue('domain')) !== -1) {
        if (name == 'Outfit Created') {
            sendWKScriptMessage('bridge', {
                event_id: 'outfit_created',
            })
        } else if (name == 'Buy Clicked') {
            sendWKScriptMessage('bridge', {
                event_id: 'outfit_details',
            })
        } else if (name == 'Multi-Experience Selected') {
            sendWKScriptMessage('bridge', {
                event_id: 'menu_item_clicked',
                gender: finalProperties.multi_experience_name.toLowerCase(),
            })
        }
    }
}

const canUseAnalytics = (force = false) => {
    if (hasAnalytics() || hasGoogleAnalytics()) {
        const isInitiated = isTrackingInitiated
        if (!isTrackingInitiated || force) {
            initTracking(force)
            if (!isInitiated) {
                doTrackEvent('Init', null, 'Experience')
            }
        }
        return true
    }
    return false
}

export const trackPage = async () => {
    if (canUseAnalytics()) {
        doTrackPage()
    }
}

const eventQueue = []
export const trackEvent = async (name: string, properties?: any, category: string = null) => {
    if (store.getState().profile.company) {
        if (canUseAnalytics()) {
            while (eventQueue.length) {
                const eventData = eventQueue.shift()
                doTrackEvent(eventData.name, eventData.properties, eventData.category)
            }
            doTrackEvent(name, properties, category)
        }
    } else {
        eventQueue.push({ name, properties, category })
    }
}

export const enableAnalytics = () => {
    console.log(`Veesual - Enable Analytics`)
    canUseAnalytics(true)
    trackEvent('Analytics Enable', null, 'Experience')
}

export const disableAnalytics = () => {
    console.log(`Veesual - Disable Analytics`)
    trackEvent('Analytics Disable', null, 'Experience')
    areAnalyticsLoaded = false
    areGoogleAnalyticsLoaded = false
}
