import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import store from './store/index'

import App from './App'
import reportWebVitals from './reportWebVitals'
import { tryLoadAndStartRecorder } from '@alwaysmeticulous/recorder-loader'

// ---- Import reset.css for v4 style compatibility ----
import 'antd/dist/reset.css'

import './styles/styles.scss'

async function startApp() {
    // Record all sessions on localhost, staging stacks and preview URLs
    if (window.location.hostname.match('vercel') && process.env.REACT_APP_METICULOUS_TOKEN) {
        // Start the Meticulous recorder before you initialise your app.
        // Note: all errors are caught and logged, so no need to surround with try/catch
        await tryLoadAndStartRecorder({
            recordingToken: process.env.REACT_APP_METICULOUS_TOKEN,
            isProduction: false,
        })
    }

    // Initalise app after the Meticulous recorder is ready, e.g.
    ReactDOM.render(
        <React.StrictMode>
            <Provider store={store}>
                <App />
            </Provider>
        </React.StrictMode>,
        document.getElementById('root')
    )
}

startApp()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
