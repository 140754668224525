import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import useCustomTranslation from 'src/utils/translation'
import parse from 'html-react-parser'

import { GetGarmentDetailCall, SetDetailIdAction } from 'src/store/actions/garment'

import ImagePreviewProduct from 'src/components/image/ImagePreview'

import SizeSelect from 'src/components/select/SizeSelect'

import { formattedPrice } from 'src/utils/price'
import {
    getGarmentOptionSizes,
    addGarmentSizeToCart,
    getGarmentSizeLabel,
    isGarmentSizePreorder,
    getIsSizeGroup,
} from 'src/utils/garment'
import { trackPage, trackEvent } from 'src/utils/tracking'
import { Button, Col, Row, Typography } from 'antd'
import { useAppSelector } from 'src/store'
import useShowRecommendationsModal from 'src/utils/showRecommendationsModal'
import Loader from 'src/components/Loader'
import ColorPicker from 'src/components/ColorPicker'
import { useGetGarmentQuery } from 'src/store/api/api-garment'
import { HandleLookRequest } from 'src/store/actions/look'
import { sendWKGotoCart, sendWKGotoProductDetail } from 'src/utils/wkwebview'
import { toggleProductModal } from 'src/store/slices/layoutSlice'
import CloseButton from 'src/components/button/CloseButton'
import StartingAt from 'src/components/StartingAt'
import MultiSizeSelect from 'src/components/select/MultiSizeSelect'
import useGarmentMethodsHook from 'src/utils/garmentMethodsHook'

const { Paragraph } = Typography

const ProductsPage: React.FunctionComponent = () => {
    const dispatch = useDispatch()
    const { t } = useCustomTranslation()
    const { showModal, isLoading } = useShowRecommendationsModal()

    const detailId = useAppSelector((state) => state.garment.detailId)
    const detail = useAppSelector((state) => state.garment?.detail)
    const internal = useAppSelector((state) => state.profile?.company?.internal)
    const priceFloat = useAppSelector((state) => state.profile?.company?.price_float)
    const cartUrl = useAppSelector((state) => state.profile?.company?.external_cart_url)
    const company = useAppSelector((state) => state.profile?.company)
    const lookRequest = useAppSelector((state) => state.look?.request)

    const { convertedPrice } = useGarmentMethodsHook(detail)

    const [currentSize, setCurrentSize] = useState(null)
    const [addingToCart, setAddingToCart] = useState(false)
    const [goToCart, setGoToCart] = useState(false)
    const [colorSelected, setColorSelected] = useState<{ name: string; colorUrl: string }>()
    const [leftSideWidth, setLeftSideWidth] = useState<string>(
        `calc((100vh - 80px${
            window.innerWidth / window.innerHeight < 1 ? ' - 76px - 60px' : ' - 105px'
        }) * ${company.garment_image_ratio}${
            window.innerWidth / window.innerHeight >= 1 ? ' - 8px' : ''
        })`
    )

    const sizeSelectRef = useRef(null)

    const { data: variantData } = useGetGarmentQuery(
        {
            type: detail?.garment_type,
            page: 1,
            filter: { garment_variant_ids: [detail?.garment_id] },
        },
        { skip: !detail || !detail.garment_variant_color }
    )

    const allVariantColors = useMemo(() => {
        if (!variantData) {
            return []
        }
        if (!variantData.items.length) {
            return [{ name: detail.product_color, colorUrl: detail.garment_variant_color }]
        }
        return variantData.items.map((variant) => {
            return { name: variant.product_color, colorUrl: variant.garment_variant_color }
        })
    }, [detail, variantData])

    // ---- Boolean used to narrow product_sizes type ----
    const isSizeGroup = useMemo(() => detail && getIsSizeGroup(detail?.product_sizes), [detail])

    const garmentOffer: Models.OldGarmentSize =
        !isSizeGroup && detail
            ? (detail?.product_sizes as Models.OldGarmentSize[]).find(
                  (size) => !('sizes' in size) && size.value === (currentSize as string)
              )
            : undefined
    const garmentPrice =
        garmentOffer?.price || convertedPrice?.product_price || detail?.product_price
    const garmentOriginalPrice =
        garmentOffer?.price_original ||
        convertedPrice?.product_price_original ||
        detail?.product_price_original
    const garmentCurrency = convertedPrice?.product_currency || detail?.product_currency

    const isPromotion = useMemo(() => {
        if (detail) {
            return !!garmentOriginalPrice && garmentOriginalPrice > garmentPrice
        }

        return false
    }, [detail, garmentPrice, garmentOriginalPrice])

    const optionSize = useMemo(
        () =>
            getGarmentOptionSizes(
                lookRequest[detail?.garment_type.toLowerCase()]
                    ? lookRequest[detail?.garment_type.toLowerCase()]
                    : detail
            ),
        [detail, lookRequest]
    )

    // ---- Init LeftSide Width with custom Eileenfisher value ----
    useEffect(() => {
        if (!internal || !internal.match(/^eileenfisher/)) {
            return undefined
        }

        setLeftSideWidth(
            `calc((100vh - 80px${
                window.innerWidth / window.innerHeight < 1 ? ' - 76px - 60px' : ' - 105px'
            }) * ${0.75}${window.innerWidth / window.innerHeight >= 1 ? ' - 8px' : ''})`
        )
    }, [internal])

    useEffect(() => {
        trackPage()

        let interval = null
        const handleResize = () => {
            const rowElement = document.getElementsByClassName('image--row')

            if (rowElement.length > 0) {
                clearInterval(interval)

                const imageRatio =
                    internal && internal.match(/^eileenfisher/) ? 0.75 : company.garment_image_ratio

                // ---- Calculate max width according to window height and config garment image ratio ----
                setLeftSideWidth(
                    `calc((100vh - ${rowElement[0].clientHeight}px${
                        window.innerWidth / window.innerHeight < 1 ? ' - 76px - 60px' : ' - 105px'
                    }) * ${imageRatio}${
                        window.innerWidth / window.innerHeight >= 1 ? ' - 8px' : ''
                    })`
                )
            }
        }

        // ---- Need interval in case rowElem is not yet loaded ----
        interval = setInterval(() => {
            handleResize()
        }, 100)

        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
            if (interval) {
                clearInterval(interval)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const fetchData = async (garmentId) => {
            await dispatch(GetGarmentDetailCall(garmentId))
        }
        if (!detail || detail.garment_id != detailId) {
            fetchData(detailId)
        }
        setGoToCart(false)
        // eslint-disable-next-line
    }, [detailId])

    useEffect(() => {
        if (detail && detail.garment_variant_color) {
            setColorSelected({
                name: detail.product_color,
                colorUrl: detail.garment_variant_color,
            })
        }
    }, [detail])

    if (!detailId || !detail) {
        return <div className='product'></div>
    }

    const handleSizeChange = (newValue: string | Models.MultiSize[] | null) => {
        if (newValue) {
            trackEvent(
                'Size Selected',
                [
                    detail,
                    {
                        item_size_selected: newValue,
                        item_size_selected_label:
                            typeof newValue === 'string'
                                ? getGarmentSizeLabel(newValue, optionSize)
                                : undefined,
                    },
                ],
                'Item Detail'
            )
        }
        setCurrentSize(newValue)
        setGoToCart(false)
    }

    const handleAddToCart = () => {
        let sizeGroupFieldCount = 0
        detail?.product_sizes.forEach((garmentSize) => {
            if ((garmentSize as Models.GarmentSize).fields) {
                sizeGroupFieldCount += garmentSize.fields.length
            }
        })

        if (!currentSize || (isSizeGroup && currentSize.length !== sizeGroupFieldCount)) {
            return sizeSelectRef.current?.focus()
        }
        trackEvent(
            'Item Added to cart',
            [
                detail,
                {
                    item_size_selected: currentSize,
                    item_size_selected_label:
                        typeof currentSize === 'string'
                            ? getGarmentSizeLabel(currentSize, optionSize)
                            : undefined,
                },
                lookRequest.model,
            ],
            'Item Detail'
        )
        setAddingToCart(true)
        addGarmentSizeToCart(detail, currentSize, 'Item Detail', (success) => {
            if (!success) {
                trackEvent(
                    'Error Adding item to cart',
                    [
                        detail,
                        {
                            item_size_selected: currentSize,
                            item_size_selected_label:
                                typeof currentSize === 'string'
                                    ? getGarmentSizeLabel(currentSize, optionSize)
                                    : undefined,
                        },
                    ],
                    'Item Detail'
                )
                alert(t(`error.cart`))
            } else {
                setGoToCart(true)
                showModal(detailId, detail, 'Item Detail')
            }
            setAddingToCart(false)
        })
    }

    const handleGoToCart = (e) => {
        e.stopPropagation()
        if (sendWKGotoCart()) {
            e.preventDefault()
        }
        trackEvent('Go to cart Clicked', [detail], 'Item Detail')
    }

    const handleExtraProductClick = (e) => {
        e.stopPropagation()
        if (sendWKGotoProductDetail(detail)) {
            e.preventDefault()
        }
        trackEvent('Go to external Clicked', [detail], 'Item Detail')
    }

    const handleColorChange = (newColor: { name: string; colorUrl: string }) => {
        setColorSelected(newColor)
        const variant = variantData?.items.find(
            (localVariant) => localVariant.garment_variant_color === newColor.colorUrl
        )

        if (!variant) {
            return
        }

        dispatch(SetDetailIdAction(variant.garment_id))

        dispatch(
            HandleLookRequest({
                lookRequest: {
                    [detail.garment_type.toLowerCase()]: variant,
                },
                keepAdditional: true,
                focus: detail.garment_type,
            })
        )
    }

    const isAllAddToCartLoading = addingToCart || isLoading

    if (detailId !== detail.garment_id) {
        return <Loader />
    }

    return (
        <div className='product'>
            <CloseButton className='modal--close' onClick={() => dispatch(toggleProductModal())} />
            <Row
                className={`product--container${
                    company.enable_wishlist ? ' product--container-with-wishlist' : ''
                }`}
            >
                <Col className='product--left-side' style={{ width: leftSideWidth }}>
                    <ImagePreviewProduct garment={detail} />
                </Col>
                <Col className='product--right-side'>
                    {detail.product_brand && (
                        <h2 className='product--brand'>{parse(detail.product_brand)}</h2>
                    )}
                    <h2 className='product--title'>{parse(detail.product_name)}</h2>
                    {detail.product_subname && (
                        <Paragraph
                            ellipsis={{
                                rows: 2,
                            }}
                            className='text text--small product--subname'
                        >
                            {parse(detail.product_subname)}
                        </Paragraph>
                    )}
                    <Row className='card--price--container'>
                        {!currentSize && <StartingAt garment={detail} />}
                        {isPromotion && (
                            <h2 className='product--price--promotion'>
                                <span className='product--price--original'>
                                    {convertedPrice && convertedPrice.product_price_original_string
                                        ? convertedPrice.product_price_original_string
                                        : formattedPrice(
                                              garmentOriginalPrice,
                                              garmentCurrency,
                                              priceFloat
                                          )}
                                </span>
                                {internal === 'gemofr' ? (
                                    <span className='product--price--percent'>PROMO</span>
                                ) : (
                                    <span className='product--price--percent'>
                                        -
                                        {Math.round(
                                            ((garmentOriginalPrice - garmentPrice) * 100) /
                                                garmentOriginalPrice
                                        )}
                                        %
                                    </span>
                                )}
                            </h2>
                        )}
                        <h2
                            className={`product--price--final${
                                isPromotion ? ' product--price--final--promotion' : ''
                            }`}
                        >
                            {convertedPrice && convertedPrice.product_price_string
                                ? convertedPrice.product_price_string
                                : formattedPrice(garmentPrice, garmentCurrency, priceFloat)}
                        </h2>
                    </Row>

                    {detail.product_external_url && (
                        <Button
                            type='text'
                            className='button--underlined product--extra-product'
                            onClick={handleExtraProductClick}
                            href={detail.product_external_url}
                            target={company.link_target_parent ? '_parent' : '_blank'}
                            size='small'
                        >
                            {t('product.extra_product')}
                        </Button>
                    )}
                    {detail.garment_variant_color && (
                        <div style={{ marginBottom: 16 }}>
                            <ColorPicker
                                garment={detail}
                                colors={allVariantColors}
                                value={colorSelected}
                                onValueChange={handleColorChange}
                                eventCategory='Item Detail'
                            />
                        </div>
                    )}
                    <div
                        className={
                            isSizeGroup
                                ? 'product--bottom-container-multiselect'
                                : 'product--bottom-container'
                        }
                    >
                        {isSizeGroup ? (
                            <MultiSizeSelect
                                ref={sizeSelectRef}
                                garmentSizes={detail?.product_sizes as Models.GarmentSize[]}
                                onChange={handleSizeChange}
                                garmentDefaultFilter={detail?.product_sizes_default_filter}
                                garmentFilters={detail?.product_sizes_filters}
                            />
                        ) : (
                            <SizeSelect
                                name='size'
                                ref={sizeSelectRef}
                                options={optionSize}
                                translation={false}
                                placeholder={t('product.choose_size')}
                                value={currentSize}
                                onChange={handleSizeChange}
                            />
                        )}

                        {goToCart && cartUrl && !isAllAddToCartLoading ? (
                            <Button
                                className='button card--main button--go-to-cart'
                                onClick={handleGoToCart}
                                href={cartUrl}
                                target={company.link_target_parent ? '_parent' : '_blank'}
                                type='primary'
                                size='large'
                                key='goToCart'
                            >
                                {t('product.go_to_cart')}
                            </Button>
                        ) : (
                            <Button
                                className='button card--main button--add-to-cart'
                                loading={isAllAddToCartLoading}
                                onClick={handleAddToCart}
                                type='primary'
                                size='large'
                            >
                                {t(
                                    isGarmentSizePreorder(currentSize, optionSize)
                                        ? 'product.add_to_cart_preorder'
                                        : 'product.add_to_cart'
                                )}
                            </Button>
                        )}
                    </div>
                    <p className='product--text'>
                        {parse(
                            detail.product_description_html
                                ? detail.product_description_html
                                : detail.product_description
                        )}
                    </p>
                </Col>
            </Row>
        </div>
    )
}

export default ProductsPage
