export const formattedHeight = (height: number | string | null, format: string | null = null) => {
    if (height === null) {
        height = 0
    }
    if (format === null) {
        format = 'm'
    }

    switch (format) {
        case 'm':
            return `${Math.floor(parseInt(height as string) / 100)}m${
                parseInt(height as string) % 100
            }`
        case 'cm':
            return `${height}cm`
        case 'i':
            let inches = Math.floor(parseInt(height as string) * 0.393700787)
            const feet = Math.floor(inches / 12)
            inches = inches % 12
            return `${feet}' ${inches}"`
        default:
            return `${Math.floor(parseInt(height as string) / 100)}m${
                parseInt(height as string) % 100
            }`
    }
}
