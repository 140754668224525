import { getQueryValue } from './query'

export const formattedPrice = (
    price: number | string | null,
    currency: string | null,
    float = 0
) => {
    if (price === null) {
        price = 0
    }
    if (currency === null) {
        currency = 'EUR'
    }
    const formated = parseFloat(price as string).toFixed(float)
    if (currency == 'EUR') {
        if (getQueryValue('locale') == 'it') {
            return '€ ' + formated
        }
        return formated + ' €'
    } else if (currency == 'USD') {
        return '$' + formated
    } else if (currency == 'CAD') {
        return '$' + formated + ' CAD'
    } else if (currency == 'GBP') {
        return '£' + formated
    } else if (currency == 'CHF') {
        return 'CHF ' + formated
    } else if (currency == 'AUD') {
        return '$' + formated + ' AUD'
    } else if (currency == 'NZD') {
        return '$' + formated + ' NZD'
    } else if (currency === null) {
        return formated
    }
    return formated + ' ' + currency
}
