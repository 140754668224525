import React, { useEffect, useRef, useState } from 'react'
import { useAppSelector } from 'src/store'
import Slider from 'react-slick'
import CardModel from '../card/CardModel'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { trackEvent } from 'src/utils/tracking'

export default function CarouselModel() {
    const data = useAppSelector((state) => state.model.all?.items)
    const company = useAppSelector((state) => state.profile.company)

    const [horizontalMode, setHorizontalMode] = useState<boolean>(false)
    const [slideNb, setSlideNb] = useState<number>(Math.min(5, data?.length))
    const [normalSlideNb, setNormalSlideNb] = useState<number>(Math.min(5, data?.length))
    const [carouselMaxWidth, setCarouselMaxWidth] = useState<number>()
    const [centerPaddingValue, setCenterPaddingValue] = useState<number>(30)

    const slider: any = useRef()
    const swipeTrackingEvent: any = useRef()

    const CarouselNextArrow = (arrowProps) => {
        const { className, onClick } = arrowProps

        if (!onClick) {
            return null
        }

        const handleClick = (e) => {
            swipeTrackingEvent.current = {
                name: 'Swipe right',
                properties: { swipe_from: 'arrow' },
                category: 'Model Choice',
            }
            onClick(e)
        }

        return (
            <div
                className={`${className} carousel-swipe--arrow carousel-swipe--right`}
                onClick={handleClick}
            >
                <RightOutlined />
            </div>
        )
    }

    const CarouselPrevArrow = (arrowProps) => {
        const { className, onClick } = arrowProps

        if (!onClick) {
            return null
        }

        const handleClick = (e) => {
            swipeTrackingEvent.current = {
                name: 'Swipe left',
                properties: { swipe_from: 'arrow' },
                category: 'Model Choice',
            }
            onClick(e)
        }

        return (
            <div
                className={`${className} carousel-swipe--arrow carousel-swipe--left`}
                onClick={handleClick}
            >
                <LeftOutlined />
            </div>
        )
    }

    useEffect(() => {
        function handleResize() {
            const refElement = document.getElementById('referenceElement')
            const referenceWidth = refElement.clientWidth
            const referenceHeight = refElement.clientHeight

            //! ---- Need to be synchronized with the card max width calculus ----
            // ---- Calc center padding value ----
            setCenterPaddingValue(
                Math.max(
                    30,
                    (referenceWidth -
                        (referenceHeight - 32 - 20 - 31 - (data?.length > slideNb ? 27 : 0)) *
                            company.look_image_ratio) /
                        2
                )
            )

            // ---- Set HorizontalMode value with new height ----
            setHorizontalMode(referenceHeight <= 450 && referenceWidth / referenceHeight >= 1)

            // ---- Handle Slide Values and Carousel Max Width according to breakpoints ----
            const overrideSlidesNb = referenceHeight <= 450 ? 3 : null

            if (referenceWidth / referenceHeight < 1) {
                setCarouselMaxWidth(referenceWidth)
                setSlideNb(1)
                setNormalSlideNb(1)

                return
            }

            if (referenceWidth < 1025 && referenceWidth >= 768) {
                const newSlideNb =
                    Math.min(overrideSlidesNb, data?.length) || Math.min(3, data?.length)
                setCarouselMaxWidth((referenceWidth / 3) * newSlideNb)
                setSlideNb(newSlideNb)
                setNormalSlideNb(3)

                return
            }

            const newSlideNb = Math.min(overrideSlidesNb, data?.length) || Math.min(5, data?.length)
            setCarouselMaxWidth((referenceWidth / 5) * newSlideNb)
            setSlideNb(newSlideNb)
            setNormalSlideNb(5)
        }

        handleResize()

        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [data, company.look_image_ratio, slideNb])

    const settings = {
        dots: true,
        initialSlide: 0,
        infinite: false,
        slidesToShow: slideNb,
        slidesToScroll: slideNb,
        centerMode: slideNb === 1,
        centerPadding: slideNb === 1 ? `${centerPaddingValue}px` : undefined,
        touchThreshold: 15,
        speed: 200,
        arrows: true,
        nextArrow: <CarouselNextArrow />,
        prevArrow: <CarouselPrevArrow />,
        onSwipe: (direction: string) => {
            const adaptedDirection = direction == 'right' ? 'left' : 'right'
            swipeTrackingEvent.current = {
                name: `Swipe ${adaptedDirection}`,
                properties: { swipe_from: 'image' },
                category: 'Modal',
            }
        },
        beforeChange: (current: number, next: number) => {
            // ---- Only if we moved ----
            if (current != next) {
                // ---- Si on a un event a envoyer ----
                if (swipeTrackingEvent.current) {
                    trackEvent(
                        swipeTrackingEvent.current.name,
                        [data[next], swipeTrackingEvent.current.properties],
                        swipeTrackingEvent.current.category
                    )
                }
            }
            if (swipeTrackingEvent.current) {
                swipeTrackingEvent.current = null
            }
        },
    }

    return (
        <div
            style={{
                maxWidth:
                    slideNb <= normalSlideNb && !horizontalMode ? carouselMaxWidth : undefined,
            }}
            className={`model--carousel-container ${
                data?.length > slideNb ? 'model--carousel-container--with-dots' : ''
            }`}
        >
            <Slider {...settings} ref={(slick) => (slider.current = slick)}>
                {data &&
                    data.length > 0 &&
                    data.map((item: Models.Model, itemKey: number) => (
                        <CardModel
                            key={itemKey}
                            model={item}
                            horizontalMode={horizontalMode}
                            modelHeightOffset={data.length > slideNb ? 27 : 0}
                        />
                    ))}
            </Slider>
        </div>
    )
}
