import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import useCustomTranslation from 'src/utils/translation'
import parse from 'html-react-parser'

import { HandleLookRequest } from '../../store/actions/look'
import { resizeImage } from 'src/utils/image'
import { formattedPrice } from 'src/utils/price'
import {
    getGarmentOptionSizes,
    addGarmentSizeToCart,
    getGarmentSizeLabel,
    isGarmentSizePreorder,
    getIsSizeGroup,
} from 'src/utils/garment'
import { trackEvent } from 'src/utils/tracking'

import SizeSelect from 'src/components/select/SizeSelect'
import useCustomHistory from 'src/utils/custom-history-hook'
import useShowModal from 'src/utils/showRecommendationsModal'
import ImageSmooth from '../image/ImageSmooth'
import { useAppSelector } from 'src/store'
import { FetchTypeGarmentAction, SetDetailIdAction } from 'src/store/actions/garment'
import { resetAllFilters } from 'src/store/slices/databaseSlice'
import { Button, Card, Col, Row, Typography } from 'antd'
import {
    expandCartDrawer,
    toggleFavoritesModal,
    toggleProductModal,
} from 'src/store/slices/layoutSlice'
import FavoriteButton from '../button/FavoriteButton'
import { openModelProductDetail, sendWKGotoCart } from 'src/utils/wkwebview'
import { FAVORITE } from 'src/settings/global'
import StartingAt from '../StartingAt'
import MultiSizeSelect from '../select/MultiSizeSelect'

const { Title, Paragraph } = Typography

interface CardFavoritesProps {
    data: any
    isOutfit: boolean
    ratio: number
    noClick?: boolean // Disable click on card
    customImageWidth?: number | string
    eventCategory?: string
    convertedPrice?: Models.Price
}

const CardFavorites: React.FunctionComponent<CardFavoritesProps> = (props) => {
    const {
        data,
        isOutfit,
        ratio,
        noClick,
        customImageWidth,
        eventCategory = 'Favorite',
        convertedPrice,
    } = props

    const dispatch = useDispatch()
    const { t } = useCustomTranslation()
    const customHistory = useCustomHistory()
    const { showModal, isLoading } = useShowModal()

    const priceFloat = useAppSelector((state) => state.profile?.company?.price_float)
    const descriptionCatalog = useAppSelector(
        (state) => state.profile?.company?.description_catalog
    )
    const cartUrl = useAppSelector((state) => state.profile?.company?.external_cart_url)
    const company = useAppSelector((state) => state.profile?.company)
    const internal = useAppSelector((state) => state.profile?.company?.internal)
    const useClipping = useAppSelector((state) => state.profile?.company?.use_clipping)
    const garmentTypes = useAppSelector((state) => state.profile.company.garment_types)
    const look = useAppSelector((state) => state.look.current)
    const lookRequest = useAppSelector((state) => state.look.request)

    const [currentSize, setCurrentSize] = useState(null)
    const [addingToCart, setAddingToCart] = useState(false)
    const [goToCart, setGoToCart] = useState(false)
    const [clickedGarment, setClickedGarment] = useState<boolean>(false)

    const sizeSelectRef = useRef(null)

    // ---- Boolean used to narrow product_sizes type ----
    const isSizeGroup = useMemo(() => {
        if (isOutfit) {
            return false
        }

        return data.product_sizes && getIsSizeGroup(data.product_sizes)
    }, [isOutfit, data])

    const garmentOffer: Models.OldGarmentSize =
        !isSizeGroup && !isOutfit
            ? (data.product_sizes as Models.OldGarmentSize[])?.find(
                  (size) => !('sizes' in size) && size.value === (currentSize as string)
              )
            : undefined
    const garmentPrice = garmentOffer?.price || convertedPrice?.product_price || data.product_price
    const garmentOriginalPrice =
        garmentOffer?.price_original ||
        convertedPrice?.product_price_original ||
        data.product_price_original
    const garmentCurrency = convertedPrice?.product_currency || data.product_currency

    const isPromotion = useMemo(() => {
        if (data) {
            return !!garmentOriginalPrice && garmentOriginalPrice > garmentPrice
        }

        return false
    }, [data, garmentPrice, garmentOriginalPrice])

    // ---- Get the first garment type from the look data matching the garment types array ----
    const garmentTypeFromData = useMemo(() => {
        let setGarment = data.top || data.bottom
        if (!setGarment) {
            for (let i = 0; i <= garmentTypes.length; i++) {
                if (!garmentTypes[i]) {
                    continue
                }

                const dataGarment = data[garmentTypes[i].toLowerCase()]
                if (dataGarment) {
                    setGarment = dataGarment

                    break
                }
            }
        }
        return setGarment?.garment_type
    }, [data, garmentTypes])

    const optionSize = isOutfit ? [] : getGarmentOptionSizes(data)

    const imageUrl = isOutfit
        ? data.image_urls[0]
        : useClipping && data.image_clipping_url
        ? data.image_clipping_url
        : data.image_url

    const handleCardClick = () => {
        if (noClick) {
            return
        }

        dispatch(resetAllFilters())

        if (isOutfit) {
            trackEvent('Outfit Clicked', data, eventCategory)

            // ---- Update garment type if the value exists ----
            if (garmentTypeFromData) {
                dispatch(FetchTypeGarmentAction(garmentTypeFromData))
            }

            dispatch(HandleLookRequest({ lookRequest: data, from: FAVORITE }))
        } else {
            trackEvent('Item Clicked', data, eventCategory)
            dispatch(FetchTypeGarmentAction(data.garment_type))
            dispatch(
                HandleLookRequest({
                    lookRequest: {
                        ...lookRequest,
                        [data.garment_type.toLowerCase()]: data,
                    },
                    focus: data.garment_type,
                    from: FAVORITE,
                })
            )
        }

        dispatch(toggleFavoritesModal())

        if (window.innerWidth / window.innerHeight < 1) {
            setClickedGarment(true)
        }
    }

    const handleSizeChange = (newValue: string | Models.MultiSize[] | null) => {
        if (newValue) {
            trackEvent(
                'Size Selected',
                [
                    data,
                    {
                        item_size_selected: newValue,
                        item_size_selected_label:
                            typeof newValue === 'string'
                                ? getGarmentSizeLabel(newValue, optionSize)
                                : undefined,
                    },
                ],
                eventCategory
            )
        }
        setCurrentSize(newValue)
        setGoToCart(false)
    }

    const handleAddToCart = (e) => {
        e.stopPropagation()

        let sizeGroupFieldCount = 0
        data.product_sizes.forEach((garmentSize) => {
            if ((garmentSize as Models.GarmentSize).fields) {
                sizeGroupFieldCount += garmentSize.fields.length
            }
        })

        if (!currentSize || (isSizeGroup && currentSize.length !== sizeGroupFieldCount)) {
            return sizeSelectRef.current?.focus()
        }
        trackEvent(
            'Item Added to cart',
            [
                data,
                {
                    item_size_selected: currentSize,
                    item_size_selected_label:
                        typeof currentSize === 'string'
                            ? getGarmentSizeLabel(currentSize, optionSize)
                            : undefined,
                },
                look.model,
            ],
            eventCategory
        )
        setAddingToCart(true)
        addGarmentSizeToCart(data, currentSize, eventCategory, (success) => {
            if (!success) {
                trackEvent(
                    'Error Adding item to cart',
                    [
                        data,
                        {
                            item_size_selected: currentSize,
                            item_size_selected_label:
                                typeof currentSize === 'string'
                                    ? getGarmentSizeLabel(currentSize, optionSize)
                                    : undefined,
                        },
                    ],
                    eventCategory
                )
                alert(t(`error.cart`))
            } else {
                setGoToCart(true)
                showModal(data.garment_id, data, eventCategory)
            }
            setAddingToCart(false)
        })
    }

    const handleGoToCart = (e) => {
        e.stopPropagation()
        if (sendWKGotoCart()) {
            e.preventDefault()
        }
        trackEvent('Go to cart Clicked', [data], eventCategory)
    }

    const handleSeeGarmentDetail = (e) => {
        e.stopPropagation()
        trackEvent('Item Detailed', data, eventCategory)
        if (!openModelProductDetail(data)) {
            dispatch(SetDetailIdAction(data.garment_id))
            dispatch(toggleProductModal())
        }
    }

    const handleSeeLookDetail = (e) => {
        e.stopPropagation()
        trackEvent('Outfit Detailed', data, eventCategory)

        // ---- Update garment type if the value exists ----
        if (garmentTypeFromData) {
            dispatch(FetchTypeGarmentAction(garmentTypeFromData))
        }

        dispatch(HandleLookRequest({ lookRequest: data, from: FAVORITE }))

        if (window.innerWidth / window.innerHeight < 1) {
            return dispatch(expandCartDrawer())
        }

        dispatch(toggleFavoritesModal())
    }

    // We need to wait that the look is updated with the data before navigating in mobile
    useEffect(() => {
        if (clickedGarment) {
            if (isOutfit) {
                // If data is a look we compare the look_id
                if (look.look_id === data.look_id) {
                    setClickedGarment(false)
                    return customHistory.push('/swipe')
                }
            } else {
                // If data is a garment we compare de garment_id for the correct garment_type
                if (look[data.garment_type.toLowerCase()]?.garment_id === data.garment_id) {
                    setClickedGarment(false)
                    return customHistory.push('/swipe')
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [look])

    const isAllAddToCartLoading = addingToCart || isLoading

    return (
        <Card
            className={
                'card card--container card--container-favorite override_card_container override_card_catalog_container' +
                (!isOutfit && data.product_brand ? ' card--with-brand' : '') +
                (!isOutfit && descriptionCatalog === false ? ' card--without-description' : '')
            }
            hoverable={!noClick}
            onClick={handleCardClick}
            cover={
                <div className='card card--image-container'>
                    <div
                        className='card--image override_card_cover_catalog'
                        style={customImageWidth && { width: customImageWidth }}
                    >
                        <ImageSmooth
                            className='card--background override_img_container'
                            src={resizeImage(imageUrl, { width: 800 })}
                            ratio={ratio}
                            transition={false}
                            cover={isOutfit}
                        />
                    </div>
                    <FavoriteButton
                        data={data}
                        className='card--favorite-button'
                        eventCategory={eventCategory}
                    />
                </div>
            }
        >
            {!isOutfit ? (
                <>
                    <Col
                        style={{
                            justifyContent: 'space-between',
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Col>
                            {data.product_brand && (
                                <Paragraph
                                    ellipsis={{
                                        rows: 2,
                                    }}
                                    className='text text--center text--small card--text override_card_brand'
                                >
                                    {parse(data.product_brand)}
                                </Paragraph>
                            )}
                            <Title
                                ellipsis={{
                                    rows: descriptionCatalog === false ? 2 : 1,
                                }}
                                className='title title--h3 title--center card--title override_card_title'
                            >
                                {parse(data.product_name)}
                            </Title>
                            {data.product_subname && (
                                <Paragraph
                                    ellipsis={{
                                        rows: 2,
                                    }}
                                    className='text text--center text--small card--subname'
                                >
                                    {parse(data.product_subname)}
                                </Paragraph>
                            )}
                            {descriptionCatalog !== false && (
                                <Paragraph
                                    ellipsis={{
                                        rows: 2,
                                    }}
                                    className='text text--center text--small card--text override_card_description'
                                >
                                    {parse(data.product_description)}
                                </Paragraph>
                            )}
                        </Col>
                        <Col>
                            <Row className='card--price--container'>
                                {!currentSize && (
                                    <StartingAt garment={data} style={{ textAlign: 'center' }} />
                                )}
                                {isPromotion && (
                                    <Title
                                        ellipsis={{
                                            rows: 1,
                                        }}
                                        className='title title--center card--price--promotion'
                                    >
                                        <span className='card--price--original'>
                                            {convertedPrice &&
                                            convertedPrice.product_price_original_string
                                                ? convertedPrice.product_price_original_string
                                                : formattedPrice(
                                                      garmentOriginalPrice,
                                                      garmentCurrency,
                                                      priceFloat
                                                  )}
                                        </span>
                                        {internal === 'gemofr' ? (
                                            <span className='card--price--percent'>PROMO</span>
                                        ) : (
                                            <span className='card--price--percent'>
                                                -
                                                {Math.round(
                                                    ((garmentOriginalPrice - garmentPrice) * 100) /
                                                        garmentOriginalPrice
                                                )}
                                                %
                                            </span>
                                        )}
                                    </Title>
                                )}
                                <Title
                                    ellipsis={{
                                        rows: 1,
                                    }}
                                    className={`title title--center title--h2 card--title card--price--final${
                                        isPromotion ? ' card--price--final--promotion' : ''
                                    }`}
                                >
                                    {convertedPrice && convertedPrice.product_price_string
                                        ? convertedPrice.product_price_string
                                        : formattedPrice(
                                              data.product_sizes.find(
                                                  (size) => size.value === (currentSize as string)
                                              )?.price || garmentPrice,
                                              garmentCurrency,
                                              priceFloat
                                          )}
                                </Title>
                            </Row>
                        </Col>
                    </Col>

                    <div className='card card--favorites-options'>
                        {!isOutfit && (
                            // ---- div needed to apply override margin from config ----
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Button
                                    type='text'
                                    className='button--underlined'
                                    size='small'
                                    onClick={handleSeeGarmentDetail}
                                >
                                    {t('product.see_detail')}
                                </Button>
                            </div>
                        )}
                        {isSizeGroup ? (
                            <MultiSizeSelect
                                ref={sizeSelectRef}
                                garmentSizes={data.product_sizes as Models.GarmentSize[]}
                                onChange={handleSizeChange}
                                garmentDefaultFilter={data.product_sizes_default_filter}
                                garmentFilters={data.product_sizes_filters}
                            />
                        ) : (
                            <SizeSelect
                                ref={sizeSelectRef}
                                name='size'
                                onChange={handleSizeChange}
                                placeholder={t('product.size')}
                                translation={false}
                                value={currentSize}
                                options={optionSize}
                            />
                        )}

                        {goToCart && cartUrl && !isAllAddToCartLoading ? (
                            <Button
                                className='button card--main button--go-to-cart'
                                onClick={handleGoToCart}
                                href={cartUrl}
                                target={company.link_target_parent ? '_parent' : '_blank'}
                                type='primary'
                                size='large'
                                key='goToCart'
                            >
                                {t('product.go_to_cart')}
                            </Button>
                        ) : (
                            <Button
                                className='button card--main button--add-to-cart'
                                loading={isAllAddToCartLoading}
                                onClick={handleAddToCart}
                                type='primary'
                                size='large'
                            >
                                {t(
                                    isGarmentSizePreorder(currentSize, optionSize)
                                        ? 'product.add_to_cart_preorder'
                                        : 'product.add_to_cart'
                                )}
                            </Button>
                        )}
                    </div>
                </>
            ) : (
                <Button
                    type='text'
                    className='button--underlined favorites--look-detail'
                    onClick={handleSeeLookDetail}
                    size='small'
                >
                    {t('look.see_detail')}
                </Button>
            )}
        </Card>
    )
}

export default CardFavorites
